const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列

            columnsData: [{
                label: "中文名称",
                prop: "HCMC_ZW",
            }, {
                label: "拉丁语学名",
                prop: "HCMC_LDY"
            }, {
                label: "分布样方",
                prop: "SFYF"
            }, {
                label: "总受害面积(m²)",
                prop: "SHMJ_HJ"
            }, {
                label: "总受害数量(个)",
                prop: "SHSL_HJ"
            }, {
                label: "汇总人",
                prop: "HZR"
            }, {
                label: "汇总日期",
                prop: "HZRQ",
                substr: true
            },
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
    },
    snButton: {
        buttonData: [
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    }
};
export default selfData;